import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useMileageExpress } from './mileage-express-provider';
import { useMileageExpressFilter } from './filters/mileage-express-filter-provider';

const MileageExpressActions = () => {
  const {
    saveAllMileageExpresss,
    addingMileageForGFVehicle,
    onExport,
    significantMileageEntries,
    dispatchAction,
  } = useMileageExpress();

  const { filters } = useMileageExpressFilter();

  return (
    <div className="display-flex flex-justify-end">
      <Button
        data-testid="save-all-mileageExpress-submit-btn"
        onClick={() => {
          if (significantMileageEntries?.length > 0) {
            dispatchAction('SET_CONFIRM_SIGNIFICANT_MILEAGE_DATA', true);
          } else {
            saveAllMileageExpresss();
          }
        }}
        label="Save all"
        disabled={addingMileageForGFVehicle}
      />
      <Button
        data-testid="export-mileage-express-btn"
        variant="outline"
        onClick={() => onExport(filters)}
        leftIcon={{ name: 'file_download' }}
        label="Export"
      />
    </div>
  );
};

export default MileageExpressActions;
