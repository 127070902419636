/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Alert,
  Spinner,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { usePm } from './pm-provider';
import ManagePmFormContext from './manage-pm-form-context';
import ManagePmForm from './manage-pm-form';

const ManagePmModal = () => {
  const {
    isManagePmModalOpen,
    closeManagePmModal,
    selectedPm,
    dispatch,
    addingPm,
    updatingPm,
  } = usePm();
  const { vehicle } = useVehicle();
  const [hasFormChanged, setHasFormChanged] = useState(false);

  const onCloseModal = () => {
    dispatch({
      type: 'SET_SELECTED_PM',
      payload: undefined,
    });
    closeManagePmModal();
    setHasFormChanged(false);
  };

  if (!isManagePmModalOpen) {
    return null;
  }

  const isFormChanged = (value) => {
    setHasFormChanged(value);
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>{selectedPm ? 'Edit' : 'Report'} a PM</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onCloseModal}
                data-testid="motor-pool-listing-add-cancel-button"
                label="Cancel"
              />
              <Button
                variant="primary"
                type="submit"
                form="manage-pm-form"
                data-testid="motor-pool-listing-add-submit-button"
                disabled={addingPm || updatingPm || !hasFormChanged}
                label="Save and close"
              />
              {(addingPm || updatingPm) && (
                <Spinner
                  size="small"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={onCloseModal}
          variant="large"
        >
          {/* {(context === 'addPm' || context === 'updatePm') && message && (
            <div
              className="margin-bottom-2"
              data-testid="reservation-modal-alert"
            >
              <Alert type={type} heading={header || null}>
                {message}
              </Alert>
            </div>
          )} */}
          <p>
            {selectedPm ? 'Edit' : 'Report'} PM for VIN{' '}
            <strong>{vehicle?.id}</strong> in the form below.
          </p>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <ManagePmFormContext>
            <ManagePmForm isFormChanged={isFormChanged} />
          </ManagePmFormContext>
        </Modal>
      </div>
    </div>
  );
};

export default ManagePmModal;
