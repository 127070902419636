import React from 'react';
import { getValueWithFallbackValue } from 'utilities/common';

const VehicleMakeModel = ({ vehicle }) => {
  return (
    <>
      {getValueWithFallbackValue(
        vehicle?.modelYear <= 0 ? 'UNKNOWN' : vehicle?.modelYear,
        () => '',
      )}{' '}
      {getValueWithFallbackValue(vehicle?.vehicleMake?.makeName, () => '')}{' '}
      {getValueWithFallbackValue(
        vehicle?.vehicleModel?.modelDescription || vehicle?.modelCode,
        () => '',
      )}
    </>
  );
};

export default VehicleMakeModel;
