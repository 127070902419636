/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tag } from '@gsa/afp-component-library';
import VehicleMakeModel from 'components/common/vehicle-make-model';

const Header = ({ vehicle, title }) => {
  return (
    <>
      <div className="display-flex flex-align-center">
        {title === 'Edit Expense Record' && (
          <>
            <Tag variant="Important-Dark">Edit</Tag>
            <span />
          </>
        )}

        <h1 className="margin-right-3">{title}</h1>
      </div>
      <div data-testid="vehicle-make-model">
        <h2 className="title-m margin-top-0 margin-bottom-1 text-uppercase">
          <VehicleMakeModel vehicle={vehicle} />
        </h2>
      </div>
      {vehicle?.tagNumber && (
        <div data-testid="vehicle-attached-license-plate">
          <h3 className="title-m margin-top-0 text-primary">
            <Link
              to={`/license-plate/${vehicle?.tagNumber}?tagExpirationDate=${vehicle?.tag?.expirationDate}`}
            >
              {vehicle?.tagNumber}
            </Link>
          </h3>
        </div>
      )}
    </>
  );
};

export default Header;

Header.propTypes = {
  vin: PropTypes.string.isRequired,
};
