/* eslint-disable react/prop-types */
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import {
  CountrySelect,
  TextInput,
  Alert,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { getCurrentOdometerText } from 'utilities/format';
import { STATES_LIST } from 'utilities/consts';
import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { getAssignVehicleFormSchema } from './assign-vehicle-form-schema';

export const AssignVehicleForm = ({
  onSubmit,
  assignmentData,
  setAssignmentData,
}) => {
  const { vehicle } = useVehicle();
  const assignVehicleRef = useRef(null);

  useEffect(() => {
    if (!assignVehicleRef.current) return () => {};
    const subscription = assignVehicleRef.current.watch((data) => {
      setAssignmentData({
        ...assignmentData,
        ...data,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [assignVehicleRef.current]);

  const formatStateOptions = () => {
    return STATES_LIST.map((state) => {
      return {
        label: state.stateName,
        value: state.stateCode,
      };
    });
  };

  const today = new Date();

  const defaultFormData = useMemo(
    () => ({
      beginOdometer: '',
      endOdometer: '',
      garagePostalCode: '',
      assignmentDate: '',
      garageAddress1: '',
      garageAddress2: '',
      garageAddress3: '',
      garageCity: '',
      garageStateCode: '',
      garageCountryCode: '',
      fundCode: '',
      account1: '',
      account2: '',
      comment: '',
      numberOfKeys: '',
      currentOdometer: getCurrentOdometerText(
        vehicle?.newOdometerReading?.odometer,
      ),
    }),
    [],
  );

  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    gap: '100px',
    sections: [
      {
        sections: [
          {
            fields: [
              {
                id: 'garageCountryCode',
                label: 'Country',
                component: (config, useFormContext) => {
                  const { setValue } = useFormContext();
                  const handleCountryChange = (e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    setValue(config.id, value);
                  };
                  return (
                    <CountrySelect
                      name="country"
                      id="country"
                      placeholder
                      onChange={handleCountryChange}
                    />
                  );
                },
              },
              {
                id: 'garageAddress1',
                type: 'text',
                label: 'Address line 1',
              },
              {
                id: 'garageAddress2',
                type: 'text',
                label: 'Address line 2',
              },
              {
                id: 'garageAddress3',
                type: 'text',
                label: 'Address line 3',
              },
            ],
          },
          {
            fieldLayout: 'horizontal',
            fields: [
              {
                columnWidth: 'col-3-12',
                id: 'garageCity',
                type: 'text',
                label: 'City',
              },
              {
                columnWidth: 'col-2-12',
                id: 'garageStateCode',
                type: 'select',
                label: 'State',
                options: formatStateOptions(),
              },
            ],
          },
          {
            fields: [
              {
                columnWidth: 'col-2-12',
                id: 'garagePostalCode',
                type: 'text',
                label: 'Postal code',
                required: true,
                element: {
                  control: {
                    maxLength: 5,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        sections: [
          {
            fields: [
              {
                id: 'fundCode',
                type: 'text',
                label: 'Fund code',
                element: {
                  control: {
                    maxLength: 2,
                    countdown: true,
                  },
                },
              },
              {
                id: 'account1',
                type: 'text',
                label: 'Description 1',
                element: {
                  control: {
                    maxLength: 8,
                    countdown: true,
                  },
                },
              },
              {
                id: 'account2',
                type: 'text',
                label: 'Description 2',
                element: {
                  control: {
                    maxLength: 8,
                    countdown: true,
                  },
                },
              },
            ],
          },
          {
            fields: [
              {
                columnWidth: 'col-3-12',
                id: 'assignmentDate',
                type: 'datepicker',
                label: 'Assignment date',
                required: true,
                element: {
                  control: {
                    maxDate: today.toISOString(),
                    minDate: new Date(
                      today.getFullYear(),
                      today.getMonth(),
                      1,
                    ).toISOString(),
                  },
                },
              },
            ],
          },
          {
            fields: [
              {
                id: 'currentOdometer',
                label: 'Current Odometer',
                type: 'text',
                disabled: true,
              },
              {
                columnWidth: 'col-6-12',
                id: 'beginOdometer',
                label: (
                  <>
                    Begin mileage <RequiredFieldIndicator />
                  </>
                ),
                component: (config, useFormContext) => {
                  const [warning, setWarning] = useState('');
                  const { setValue } = useFormContext();
                  const handleBeginMileageChange = (e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    setValue(config.id, value);

                    const currentOdometer =
                      vehicle?.newOdometerReading?.odometer || 0;
                    if (
                      Math.abs(Number(value) - Number(currentOdometer)) > 9999
                    ) {
                      setWarning(
                        <Alert
                          size="small"
                          type="warning"
                          className="usa-error-message"
                        >
                          Mileage exceeds +/- 9,999 from current odometer.
                          Verify mileage before assigning.
                        </Alert>,
                      );
                    } else {
                      setWarning('');
                    }
                  };
                  return (
                    <>
                      {warning}
                      <TextInput
                        style={{ width: 245 }}
                        name="beginOdometer"
                        id="beginOdometer"
                        onChange={handleBeginMileageChange}
                      />
                    </>
                  );
                },
              },
            ],
          },
          {
            fields: [
              {
                columnWidth: 'col-6-12',
                id: 'numberOfKeys',
                type: 'number',
                label: 'Number of keys',
              },
              {
                id: 'comment',
                type: 'textarea',
                label: 'Remarks',
                element: {
                  control: {
                    maxLength: 100,
                    countdown: true,
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  };
  return (
    <FormGenerator
      ref={assignVehicleRef}
      id="assign-vehicle-form"
      data-testid="assign-vehicle-form"
      schema={getAssignVehicleFormSchema(vehicle?.newOdometerReading?.odometer)}
      defaultValues={defaultFormData}
      content={formContent}
      onSubmit={onSubmit}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};
