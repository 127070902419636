import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  ButtonDropdown,
  ButtonDropdownItem,
  Spinner,
  Button,
} from '@gsa/afp-component-library';
import { vehToPDFpayload } from 'utilities/format';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
  canUpdateTagGF,
} from 'utilities/authorization';
import { reduce, map, omit, get } from 'lodash';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { CREATE_SUPPORTING_DOC } from '../../../services/data-layer';

import { getRegistrationPDFPayload } from '../../registration-details/helpers/payload-constructors';
import { useVehicle } from '../vehicle-context-provider';
import { determineRegistrationStatus } from '../../vehicle-registration-status-tag';
import AssignVehicle from './assign/assign-vehicle';

import {
  ASSIGN_VEHICLE,
  CREATE_OR_UPDATE_STOLEN_VEHICLE,
  GET_STOLEN_VEHICLE_DATA,
  TERMINATE_VEHICLE,
  REASSIGN_FSR,
  SET_LIFYCYCLE_INDICATOR,
  DEACTIVE_LIFYCYCLE_INDICATOR,
} from '../components/graphql-queries';
import TerminateVehicle from './terminate/terminate-vehicle';
import ReportStolen from './report-stolen/report-stolen';
import ReassignVehicle from './reassign/reassign-vehicle';
import UpdateVehicleStatus from './update-vehicle-status';
import UpdatePlate from './update-plate';

export const createAssignmentInput = (data) => {
  return omit(
    {
      ...data,
      endOdometer: Number(data?.beginOdometer + 100),
    },
    'currentOdometer',
  );
};

const VehicleActions = ({ downloadRegLinkOnly, linkText }) => {
  const { vehicle, setPageMsg, refetchVehicle } = useVehicle();
  const { currentUser } = useCurrentUser();
  const ability = useAppAbility();
  const isGfVehicleActionsEnabled = isFeatureEnabled('gf-vehicle-actions');
  const [reassignFSR, setReassignFSR] = useState(false);
  const [modalError, setModalError] = useState(null);
  const [openModal, setOpenModal] = useState('');
  const [lifecycles, setLifecycles] = useState([]);
  const [reportData, setReportData] = useState({ assetId: vehicle?.uuid });
  const history = useHistory();

  // this sets up a map of the current active lifecycle indicator
  // plus all the specific statuses it is in for easy reference
  // does not assume that a vehicle can be in only a single lifecycle indicator
  // at a time so page does not break if that happens
  useEffect(() => {
    const vehicleLifecycleData = get(vehicle, 'assetLifecycle', []);
    if (vehicleLifecycleData.length > 0) {
      setLifecycles(
        reduce(
          vehicleLifecycleData,
          (result, { lifeCycle }) => {
            if (result[lifeCycle.lifecycleIndicator])
              return {
                [lifeCycle.lifecycleIndicator]: [
                  ...result[lifeCycle.lifecycleIndicator],
                  lifeCycle.lifecycleIndicatorId,
                ],
              };
            return {
              ...result,
              [lifeCycle.lifecycleIndicator]: [lifeCycle.lifecycleIndicatorId],
            };
          },
          {
            [vehicleLifecycleData[0]?.lifeCycle.lifecycleIndicator]: [],
          },
        ),
      );
    }
  }, [vehicle]);

  const [createSupportingDoc, { loading: creatingSupportingDoc }] = useMutation(
    CREATE_SUPPORTING_DOC,
    {
      fetchPolicy: 'no-cache',
      onError: () =>
        setPageMsg({
          type: 'error',
          message:
            'An unexpected error has occurred when generating registration card.',
        }),
      onCompleted: () =>
        setPageMsg({
          type: 'success',
          message: 'Successfully generated registration card.',
        }),
    },
  );

  const [assignVehicle, { loading: assignVehicleLoading }] = useMutation(
    ASSIGN_VEHICLE,
    {
      fetchPolicy: 'no-cache',
      onError: () =>
        setModalError({
          type: 'error',
          context: 'assignVehicle',
          message:
            'An unexpected error has occurred while attempting to assign the customer.',
        }),
      onCompleted: () => {
        setPageMsg({
          type: 'success',
          message: 'Successfully assigned vehicle to the customer.',
        });
        setOpenModal('');
        refetchVehicle();
      },
    },
  );

  const [terminateVehicle, { loading: terminateVehicleLoading }] = useMutation(
    TERMINATE_VEHICLE,
    {
      fetchPolicy: 'no-cache',
      onError: () =>
        setModalError({
          type: 'error',
          context: 'terminateVehicle',
          message:
            'An unexpected error has occurred while attempting to terminate the customer.',
        }),
      onCompleted: () => {
        setPageMsg({
          type: 'success',
          message: 'Successfully terminated from the customer.',
        });
        setOpenModal('');
        refetchVehicle();
      },
    },
  );

  const [reassignVehicleToFSR] = useMutation(REASSIGN_FSR, {
    fetchPolicy: 'no-cache',
    onError: () => {
      setPageMsg({
        type: 'error',
        message: 'Tehcnical Error Occured.',
      });
      setReassignFSR(false);
    },
    onCompleted: () => {
      setPageMsg({
        type: 'success',
        message: 'Successfully reassigned FSR.',
      });
      history.go();
    },
  });

  const [reportStolenVehicle, { loading: reportStolenVehicleLoading }] =
    useMutation(CREATE_OR_UPDATE_STOLEN_VEHICLE, {
      fetchPolicy: 'no-cache',
      onError: () =>
        setModalError({
          type: 'error',
          context: 'stolenVehicle',
          message: 'An unexpected error has occurred',
        }),
      onCompleted: () => {
        setPageMsg({
          type: 'success',
          message: 'The report was succesfully submitted.',
        });
        setOpenModal('');
        refetchVehicle();
      },
    });

  const [getStolenVehicleData, { loading: getStolenVehicleLoading }] =
    useLazyQuery(GET_STOLEN_VEHICLE_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (responseData) => {
        if (responseData.getVehicleLostStolen) {
          const {
            assetId,
            stolenDate,
            gsaReportedDate,
            dhsReportedDate,
            recoveredDate,
            dhsCaseNumber,
            ncicNumber,
            comment,
          } = responseData.getVehicleLostStolen;
          setReportData({
            assetId,
            stolenDate,
            gsaReportedDate,
            dhsReportedDate,
            recoveredDate,
            dhsCaseNumber,
            ncicNumber,
            comment,
          });
        }
      },
    });

  const [setLifecycleIndicators, { loading: updateVehicleStatusLoading }] =
    useMutation(SET_LIFYCYCLE_INDICATOR, {
      fetchPolicy: 'no-cache',
      onError: () =>
        setModalError({
          type: 'error',
          context: 'updateStatus',
          message:
            'An unexpected error has occurred while attempting to change the vehicle status.',
        }),
      onCompleted: () => {
        setPageMsg({
          type: 'success',
          message: 'Successfully updated vehicle status.',
        });
        setOpenModal('');
        refetchVehicle();
      },
    });

  // DEACTIVE_LIFYCYCLE_INDICATOR
  const [
    deactiveLifecycleIndicators,
    { loading: deactiveVehicleStatusLoading },
  ] = useMutation(DEACTIVE_LIFYCYCLE_INDICATOR, {
    fetchPolicy: 'no-cache',
    onError: () =>
      setModalError({
        type: 'error',
        context: 'updateStatus',
        message:
          'An unexpected error has occurred while attempting to change the vehicle status.',
      }),
    onCompleted: () => {
      setPageMsg({
        type: 'success',
        message: 'Successfully updated vehicle status.',
      });
      setOpenModal('');
      refetchVehicle();
    },
  });

  const createPDF = () => {
    const payload = getRegistrationPDFPayload(vehToPDFpayload(vehicle));
    return createSupportingDoc({
      variables: {
        data: payload,
        model: 'Vehicle',
        modelPK: vehicle.id,
        documentName: 'registration',
      },
    });
  };

  const actionTrigger = (trigger) => {
    if (typeof trigger === 'function') {
      trigger();
    }
  };

  let VEHICLE_ACTIONS = [];
  const registrationStatus = determineRegistrationStatus(vehicle);

  const setReassignFSRToVehicle = () => {
    setReassignFSR(true);
  };

  const downloadRegCard = async () => {
    const response = await createPDF();
    if (response?.data)
      window.open(response?.data?.createSupportingDoc, '_blank');
  };
  const registeredActions = [
    {
      label: linkText,
      iconName: 'file_download',
      trigger: downloadRegCard,
    },
  ];

  const isRegisteredVehicle = (v) => {
    return (
      (!!v?.tag || v?.exemptPlate) &&
      v?.itemInventoryStatusCode !== 'MS' &&
      v?.itemInventoryStatusCode !== 'SD' &&
      registrationStatus !== 'Unregistered' &&
      registrationStatus !== 'Incomplete'
    );
  };

  if (vehicle && isRegisteredVehicle(vehicle)) {
    VEHICLE_ACTIONS = [...VEHICLE_ACTIONS, ...registeredActions];
  }

  const userCanUpdateVehicle = () => {
    return (
      vehicle?.ownershipTypeCode === 'GF' &&
      (canUpdateGFVehicleAdmin(ability) || canUpdateGFVehicleFSR(ability))
    );
  };

  const userCanUpdateGfTag = () => {
    return vehicle?.ownershipTypeCode === 'GF' && canUpdateTagGF(ability);
  };

  if (
    isGfVehicleActionsEnabled &&
    vehicle &&
    userCanUpdateVehicle() &&
    lifecycles.Active &&
    (lifecycles.Active.includes('601') || lifecycles.Active.includes('605')) &&
    !lifecycles.Active.includes('603')
  ) {
    VEHICLE_ACTIONS = [
      ...VEHICLE_ACTIONS,
      {
        label: 'Assign',
        iconName: 'edit',
        trigger: () => setOpenModal('assign'),
      },
    ];
  }
  if (
    isGfVehicleActionsEnabled &&
    vehicle &&
    userCanUpdateVehicle() &&
    lifecycles.Active &&
    lifecycles.Active.includes('603')
  ) {
    VEHICLE_ACTIONS = [
      ...VEHICLE_ACTIONS,
      {
        label: 'Terminate',
        iconName: 'delete',
        trigger: () => setOpenModal('terminate'),
      },
    ];
  }
  if (
    isGfVehicleActionsEnabled &&
    vehicle &&
    userCanUpdateVehicle() &&
    (lifecycles.Active || lifecycles['Missing/Stolen'])
  ) {
    VEHICLE_ACTIONS = [
      ...VEHICLE_ACTIONS,
      {
        label: lifecycles['Missing/Stolen']
          ? 'Update missing/stolen vehicle'
          : 'Report missing/stolen vehicle',
        iconName: lifecycles['Missing/Stolen'] ? 'edit' : 'warning',
        trigger: () => setOpenModal('stolen'),
      },
    ];
  }
  if (
    isGfVehicleActionsEnabled &&
    vehicle &&
    userCanUpdateVehicle() &&
    lifecycles['Missing/Stolen']
  ) {
    VEHICLE_ACTIONS = [
      ...VEHICLE_ACTIONS,
      {
        label: 'Report recovered',
        iconName: 'edit',
        trigger: () => setOpenModal('recover'),
      },
    ];
  }
  if (
    isGfVehicleActionsEnabled &&
    vehicle &&
    userCanUpdateVehicle() &&
    lifecycles.Active &&
    lifecycles.Active.includes('605')
  ) {
    VEHICLE_ACTIONS = [
      ...VEHICLE_ACTIONS,
      {
        label: 'Transfer vehicle',
        iconName: 'people',
        trigger: setReassignFSRToVehicle,
      },
    ];
  }
  if (
    isGfVehicleActionsEnabled &&
    vehicle &&
    userCanUpdateGfTag() &&
    lifecycles.Active
  ) {
    VEHICLE_ACTIONS = [
      ...VEHICLE_ACTIONS,
      {
        label: 'Change plate',
        iconName: 'autorenew',
        trigger: () => setOpenModal('updatePlate'),
      },
    ];
  }

  if (
    vehicle &&
    isGfVehicleActionsEnabled &&
    userCanUpdateVehicle() &&
    (lifecycles.Active || lifecycles.Delivered)
  ) {
    VEHICLE_ACTIONS = [
      ...VEHICLE_ACTIONS,
      {
        label: 'Update vehicle status',
        iconName: 'edit',
        trigger: () => setOpenModal('update-vehicle-status'),
      },
    ];
  }

  if (creatingSupportingDoc) {
    return (
      <Spinner aria-busy="true" className="loading_backdrop" size="large" />
    );
  }

  if (downloadRegLinkOnly)
    return (
      vehicle &&
      isRegisteredVehicle(vehicle) && (
        <Button variant="unstyled" label={linkText} onClick={downloadRegCard} />
      )
    );

  const updateVehicleStatus = (value, operator) => {
    if (operator === 'add') {
      setLifecycleIndicators({
        variables: value,
      });
    } else {
      deactiveLifecycleIndicators({
        variables: value,
      });
    }
  };

  const closeModal = () => {
    setModalError(null);
    setOpenModal('');
  };

  const modal = () => {
    switch (openModal) {
      case 'assign':
        return (
          <AssignVehicle
            assignVehicleError={modalError}
            setModalError={setModalError}
            currentFsr={currentUser?.email}
            vehicle={vehicle}
            onClose={closeModal}
            onSave={(data) => {
              if (data?.beginOdometer) {
                assignVehicle({
                  variables: {
                    assignmentInput: createAssignmentInput(data),
                  },
                });
              }
            }}
            assignVehicleLoading={assignVehicleLoading}
          />
        );
      case 'terminate':
        return (
          <TerminateVehicle
            terminateVehicleError={modalError}
            setModalError={setModalError}
            onSave={(data) => {
              terminateVehicle({
                variables: {
                  terminationInput: { ...data },
                },
              });
            }}
            vehicle={vehicle}
            onClose={closeModal}
            terminateVehicleLoading={terminateVehicleLoading}
          />
        );
      case 'stolen':
        return (
          <ReportStolen
            reportStolenVehicleError={modalError}
            setModalError={setModalError}
            mode={lifecycles['Missing/Stolen'] ? 'update' : 'create'}
            getStolenData={getStolenVehicleData}
            submitStolenReport={() =>
              reportStolenVehicle({
                variables: {
                  assetLostStolenInput: {
                    ...reportData,
                  },
                },
              })
            }
            vehicle={vehicle}
            onClose={closeModal}
            getDataLoading={getStolenVehicleLoading}
            reportData={reportData}
            setReportData={setReportData}
            reportStolenVehicleLoading={reportStolenVehicleLoading}
          />
        );
      case 'update-vehicle-status':
        return (
          <UpdateVehicleStatus
            updateVehicleStatusError={modalError}
            setModalError={setModalError}
            vehicle={vehicle}
            statusList={lifecycles}
            onSave={updateVehicleStatus}
            onClose={closeModal}
            updateVehicleStatusLoading={updateVehicleStatusLoading}
            deactiveVehicleStatusLoading={deactiveVehicleStatusLoading}
            isAdmin={canUpdateGFVehicleAdmin(ability)}
          />
        );
      case 'recover':
        return (
          <ReportStolen
            reportStolenVehicleError={modalError}
            setModalError={setModalError}
            mode="recover"
            getStolenData={getStolenVehicleData}
            submitStolenReport={() =>
              reportStolenVehicle({
                variables: {
                  assetLostStolenInput: {
                    ...reportData,
                  },
                },
              })
            }
            vehicle={vehicle}
            onClose={closeModal}
            getDataLoading={getStolenVehicleLoading}
            reportData={reportData}
            setReportData={setReportData}
            reportStolenVehicleLoading={reportStolenVehicleLoading}
          />
        );
      case 'updatePlate':
        return <UpdatePlate onClose={closeModal} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {VEHICLE_ACTIONS.length > 0 && (
        <ButtonDropdown label="Vehicle Actions" variant="primary" side="right">
          {map(VEHICLE_ACTIONS, (action) => {
            if (action?.hidden) {
              return null;
            }
            return (
              <ButtonDropdownItem
                key={action}
                label={action?.label}
                iconName={action?.iconName}
                onClick={() => {
                  return action?.trigger
                    ? actionTrigger(action?.trigger)
                    : undefined;
                }}
                className="text-primary hover:bg-primary-lightest hover:text-primary"
              />
            );
          })}
        </ButtonDropdown>
      )}
      {reassignFSR && (
        <ReassignVehicle
          vehicle={vehicle}
          onSave={(data) => {
            reassignVehicleToFSR({
              variables: {
                reassignFSRInput: { ...data },
              },
            });
          }}
          onClose={() => setReassignFSR(false)}
        />
      )}
      {modal()}
    </>
  );
};
VehicleActions.propTypes = {
  downloadRegLinkOnly: PropTypes.bool,
  linkText: PropTypes.string,
};
VehicleActions.defaultProps = {
  downloadRegLinkOnly: false,
  linkText: 'Download registration card',
};

export default VehicleActions;
