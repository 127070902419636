/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import usePortalModal from 'utilities/portal-modal';
import {
  Button,
  RequiredFieldIndicator,
  Label,
  Icon,
} from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';
import { emdash } from 'components/common';
import { formatUSCurrency } from '../../vehicle-overview/helpers/common';

const requiredFields = ['capitalValue'];

export const getPercentage = (value) => {
  if (value) return `${value}%`;
  return emdash;
};

export default function ValuationEdit({
  vehicle,
  valuationData,
  editingValuation,
  onSave,
  onClose,
}) {
  const [ValuationEditModal, openModal, closeModal] = usePortalModal();
  const [editedData, setEditedData] = useState({});
  const [, setFieldError] = useState({});

  const isOwnershipAO = vehicle?.ownershipTypeCode === 'AO';
  const isOwnershipGF = vehicle?.ownershipTypeCode === 'GF';

  const handleClose = () => {
    setFieldError({});
    setEditedData({});
    closeModal();
    onClose();
  };

  useEffect(() => {
    if (editingValuation) {
      setEditedData(valuationData);
      openModal();
    } else {
      handleClose();
    }
  }, [editingValuation]);

  const getFieldError = (field) => {
    if (isOwnershipGF && !editedData[field]) return 'Field is required';
    if (isOwnershipGF && field === 'capitalValue' && editedData[field] === 0)
      return 'Can not be 0.';
    return null; // Default return value
  };

  const getFieldErrors = () => {
    const errors = requiredFields.reduce((acc, field) => {
      const error = getFieldError(field);
      if (error) acc[field] = error;
      return acc;
    }, {});
    return errors;
  };
  const handleSave = () => {
    const errors = getFieldErrors();
    if (isEmpty(errors)) {
      handleClose();
      onSave(editedData);
    } else {
      setFieldError(errors);
    }
  };

  const handleSalvageDataChange = (field, value) => {
    const data = { ...editedData };
    data[field] = value;
    setEditedData(data);
  };

  return (
    <ValuationEditModal
      title={<h2>Edit valuation data information</h2>}
      onClose={handleClose}
      actions={
        <>
          <Button variant="unstyled" onClick={handleClose} label="Close" />
          <Button
            className="margin-left-2"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <p>
        Edit valuation information for VIN <strong>{vehicle?.id}</strong> in the
        form below.
      </p>

      <span className="text-italic">
        Required fields are marked with an asterisk (
        <RequiredFieldIndicator />
        ).
      </span>

      {isOwnershipAO && (
        <>
          <div>
            <div className="grid-row grid-gap grid-col-12 margin-top-2">
              <div className="tablet:grid-col-6">
                <label htmlFor="acquisition-cost">
                  <span className="text-bold">Acquisition cost</span>
                </label>
                <div className="dollar-input">
                  <Icon
                    iconName="attach_money"
                    className="prefix-icon-dollar"
                  />
                  <NumberFormat
                    value={editedData?.acquisitionCost}
                    thousandSeparator
                    thousandsGroupStyle="thousand"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator="."
                    className="usa-input"
                    id="acquisition-cost"
                    name="acquisition-cost"
                    onValueChange={({ value }) =>
                      handleSalvageDataChange('acquisitionCost', value)}
                  />
                </div>
              </div>

              <div className="tablet:grid-col-6">
                <label htmlFor="disposal-capital-value">
                  <span className="text-bold">Capitalized value</span>
                </label>
                <div className="dollar-input">
                  <Icon
                    iconName="attach_money"
                    className="prefix-icon-dollar"
                  />
                  <NumberFormat
                    value={editedData?.capitalValue}
                    thousandSeparator
                    thousandsGroupStyle="thousand"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator="."
                    className="usa-input"
                    id="disposal-capital-value"
                    name="disposal-capital-value"
                    onValueChange={({ value }) =>
                      handleSalvageDataChange('capitalValue', value)}
                  />
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-2">
                <label htmlFor="disposal-book-value">
                  <span className="text-bold">Capitalized item</span>
                </label>
                <div data-test-id="capitalizedItem" className="text-tabular">
                  {editedData?.capitalizedItem}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-2">
                <label htmlFor="disposal-book-value">
                  <span className="text-bold">Book value</span>
                </label>
                <div data-test-id="bookValue" className="text-tabular">
                  {formatUSCurrency(editedData?.bookValue) || emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-2">
                <Label htmlFor="salvagePercent-field">
                  <span className="text-bold">Salvage percent</span>
                </Label>

                <div className="tablet:grid-col-6">
                  <div data-test-id="salvagePercent" className="text-tabular">
                    {getPercentage(editedData?.salvagePercent) || emdash}
                  </div>
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-2">
                <Label htmlFor="usefulLifeMonths-field">
                  <span className="text-bold">Useful life (months)</span>
                </Label>

                <div className="tablet:grid-col-6">
                  <div data-test-id="usefulLifeMonths" className="text-tabular">
                    {editedData.usefulLifeMonths
                      ? editedData.usefulLifeMonths
                      : emdash}
                  </div>
                </div>
              </div>

              <div className="tablet:grid-col-6">
                <Label htmlFor="usefulLifeMiles-field">
                  <span className="text-bold">Useful life (miles)</span>
                </Label>

                <div className="tablet:grid-col-6">
                  <div data-test-id="usefulLifeMiles" className="text-tabular">
                    {editedData.usefulLifeMiles
                      ? editedData.usefulLifeMiles
                      : emdash}
                  </div>
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-2">
                <label htmlFor="disposal-depreciation-cumulative">
                  <span className="text-bold">Depreciation cumulative</span>
                </label>
                <div
                  data-test-id="depreciationCumulative"
                  className="text-tabular"
                >
                  {formatUSCurrency(editedData?.depreciationCumulative) ||
                    emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-2">
                <label htmlFor="disposal-maximum-repair-amount">
                  <span className="text-bold">Maximum repair amount</span>
                </label>
                <div
                  data-test-id="maximumRepairAmount"
                  className="text-tabular"
                >
                  {formatUSCurrency(editedData?.maximumRepairAmount) || emdash}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isOwnershipGF && (
        <>
          <div>
            <div className="grid-row grid-gap grid-col-12 margin-top-2">
              <div className="tablet:grid-col-6">
                <label htmlFor="acquisition-cost">
                  <span className="text-bold">Acquisition cost</span>
                </label>
                <div className="text-tabular">
                  {formatUSCurrency(editedData?.acquisitionCost) || emdash}
                </div>
              </div>
              <div className="tablet:grid-col-6">
                <label htmlFor="disposal-capital-value">
                  <span className="text-bold">Capitalized value</span>
                </label>
                <div data-test-id="capitalValue" className="text-tabular">
                  {formatUSCurrency(editedData?.capitalValue) || emdash}
                </div>
              </div>
              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-book-value">
                  <span className="text-bold">Book value</span>
                </label>
                <div data-test-id="bookValue" className="text-tabular">
                  {formatUSCurrency(editedData?.bookValue) || emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-salvage-value">
                  <span className="text-bold">Salvage value</span>
                </label>
                <div data-test-id="salvageValue" className="text-tabular">
                  {formatUSCurrency(editedData?.salvageValue) || emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 ">
                <Label htmlFor="usefulLifeMonths-field">
                  <span className="text-bold">Depreciable useful life</span>
                </Label>

                <div
                  data-test-id="usefulLifeMonths"
                  className="tablet:grid-col-6"
                >
                  <div className="text-tabular">
                    {editedData.depreciableUsefulLife
                      ? `${editedData.depreciableUsefulLife} months`
                      : emdash}
                  </div>
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-depreciation-cumulative">
                  <span className="text-bold">Depreciation cumulative</span>
                </label>
                <div
                  data-test-id="depreciationCumulative"
                  className="text-tabular"
                >
                  {formatUSCurrency(editedData?.depreciationCumulative) ||
                    emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="forecasted-residual-value">
                  <span className="text-bold">Forecasted residual value</span>
                </label>
                <div
                  data-test-id="forecastedResidualValue"
                  className="text-tabular"
                >
                  {formatUSCurrency(editedData?.forecastedResidualValue) ||
                    emdash}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ValuationEditModal>
  );
}
