import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {
  TextInput,
  DatePicker,
  Label,
  RequiredFieldIndicator,
  FileUpload,
  Alert,
} from '@gsa/afp-component-library';
import moment from 'moment';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { usePm } from './pm-provider';
import { MANGE_PM_FIELDS } from './consts';
import {
  getInvoiceModificationFlag,
  getBase64,
  FLAG_TYPE,
  joinStringArrayExceptLast,
} from './helper';

const fileTypes = [
  '.pdf',
  '.xlsx',
  '.xls',
  'jpeg',
  'jpg',
  'png',
  'bmp',
  'txt',
  'rtf',
  'doc',
  'docx',
];

const ManagePmForm = ({ isFormChanged }) => {
  const {
    formState: { errors },
    control,
    clearErrors,
    handleSubmit,
  } = useFormContext();

  const { pmHistoryList, addPm, updatePm, selectedPm } = usePm();

  const { vehicle } = useVehicle();
  const canPerformActions = useCanPerformActions();
  const canEditPmField = (fieldName) =>
    canPerformActions.canEditPmField(fieldName);

  const [fileState, setFileState] = useState(
    selectedPm?.invoiceData?.name
      ? {
          name: selectedPm?.invoiceData?.name,
        }
      : null,
  );
  const [initialFormValues, setInitialFormValues] = useState({});
  const [hasDateInitialValueSet, setHasDateInitialValueSet] = useState(false);
  const [hasMileageInitialValueSet, setHasMileageInitialValueSet] =
    useState(false);
  const [trackChangedFiled, setTrackChangedField] = useState({});

  getInvoiceModificationFlag;

  const maxFileSize = 5;
  const currentPmDate = pmHistoryList?.rows?.[0]?.currentPmDate;
  const isEditMode = !!selectedPm;

  const onSubmit = async (formData) => {
    const invoiceModificationFlag = getInvoiceModificationFlag(
      selectedPm?.invoiceData?.name,
      fileState,
    );
    FLAG_TYPE;
    const file =
      invoiceModificationFlag === FLAG_TYPE.add ||
      invoiceModificationFlag === FLAG_TYPE.change
        ? await getBase64(fileState)
        : null;

    if (selectedPm) {
      updatePm({
        variables: {
          updatePmFields: {
            isLatest:
              selectedPm.assetPmId === pmHistoryList?.rows?.[0].assetPmId,
            assetId: vehicle?.uuid,
            assetPmId: selectedPm?.assetPmId,
            currentPmMileage: formData?.mileage,
            currentPmDate: formData?.date,
            invoiceModificationFlag,
            file,
            fileName: fileState?.name,
            fileType: fileState?.type,
            docMetaId: selectedPm?.invoiceData?.docMetaId,
          },
        },
      });
    } else {
      addPm({
        variables: {
          addPmField: {
            assetId: vehicle?.uuid,
            customerId: vehicle?.customerId,
            currentPmMileage: formData?.mileage,
            currentPmDate: formData?.date,
            invoiceModificationFlag,
            file,
            fileName: fileState?.name,
            fileType: fileState?.type,
          },
        },
      });
    }
  };

  const handleFormChange = (fieldName, value) => {
    if (initialFormValues[fieldName] && initialFormValues[fieldName] == value) {
      const updatedFieldvalues = { ...trackChangedFiled, [fieldName]: false };
      setTrackChangedField(updatedFieldvalues);
      const hasFormChanged = Object.values(updatedFieldvalues).reduce(
        (acc, current) => acc || current,
        false,
      );
      isFormChanged(hasFormChanged);
    }

    if (initialFormValues[fieldName] && initialFormValues[fieldName] != value) {
      const updatedFieldvalues = { ...trackChangedFiled, [fieldName]: true };
      setTrackChangedField(updatedFieldvalues);
      const hasFormChanged = Object.values(updatedFieldvalues).reduce(
        (acc, current) => acc || current,
        false,
      );
      isFormChanged(hasFormChanged);
    }
  };

  return (
    <div data-testid="manage-pm-form" className="grid-row">
      <div className="grid-col-5">
        <form
          data-testid="manage-pm-form"
          id="manage-pm-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name={MANGE_PM_FIELDS.date.name}
            control={control}
            render={({ field, ref }) => {
              if (!hasDateInitialValueSet) {
                setHasDateInitialValueSet(true);
                setInitialFormValues((prev) => ({
                  ...prev,
                  date: moment(field.value).format('MM/DD/YYYY'),
                }));

                if (!field.value) {
                  isFormChanged(true);
                }
              }

              return (
                <DatePicker
                  {...field}
                  ref={ref}
                  disabled={
                    isEditMode && !canEditPmField(MANGE_PM_FIELDS.date.name)
                  }
                  name={MANGE_PM_FIELDS.date.name}
                  id={MANGE_PM_FIELDS.date.id}
                  label={
                    <Label required className="text-bold">
                      {MANGE_PM_FIELDS.date.label}
                    </Label>
                  }
                  hint="mm/dd/yyyy"
                  // TODO
                  // EDIT Mode Rules for both date and miles
                  minDate={
                    !selectedPm && currentPmDate
                      ? moment(currentPmDate).add(1, 'd').format('YYYY-MM-DD')
                      : undefined
                  }
                  maxDate={moment().format('YYYY-MM-DD')}
                  onChange={(val) => {
                    field.onChange(val ? moment(val).format('YYYY-MM-DD') : '');
                    clearErrors(MANGE_PM_FIELDS.date.name);
                    handleFormChange('date', val);
                  }}
                  defaultValue={field.value || ''}
                  errorMessage={
                    errors && errors[MANGE_PM_FIELDS.date.name]
                      ? errors[MANGE_PM_FIELDS.date.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[MANGE_PM_FIELDS.date.name]
                      ? 'true'
                      : 'false'
                  }
                />
              );
            }}
          />
          <Controller
            name={MANGE_PM_FIELDS.mileage.name}
            control={control}
            render={({ field, ref }) => {
              if (!hasMileageInitialValueSet) {
                setHasMileageInitialValueSet(true);
                setInitialFormValues((prev) => ({
                  ...prev,
                  mileage: field.value,
                }));
                if (!field.value) {
                  isFormChanged(true);
                }
              }

              return (
                <TextInput
                  {...field}
                  ref={ref}
                  disabled={
                    isEditMode && !canEditPmField(MANGE_PM_FIELDS.mileage.name)
                  }
                  type="text"
                  suffix="miles"
                  onChange={(val) => {
                    field.onChange(val);
                    handleFormChange('mileage', val.target.value);
                  }}
                  label={
                    <span className="text-bold">
                      {MANGE_PM_FIELDS.mileage.label} <RequiredFieldIndicator />
                    </span>
                  }
                  name={MANGE_PM_FIELDS.mileage.name}
                  id={MANGE_PM_FIELDS.mileage.id}
                  errorMessage={
                    errors && errors[MANGE_PM_FIELDS.mileage.name]
                      ? errors[MANGE_PM_FIELDS.mileage.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[MANGE_PM_FIELDS.mileage.name]
                      ? 'true'
                      : 'false'
                  }
                />
              );
            }}
          />
        </form>
      </div>
      <FileUpload
        label="Attach Invoice"
        defaultValue={fileState}
        acceptableFiles={[
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'application/rtf',
          'image/bmp',
          'image/jpeg',
          'image/png',
          'text/plain',
        ]}
        acceptableFilesLabel={` Accept ${joinStringArrayExceptLast(
          fileTypes,
        )} file below
                 ${maxFileSize} MB`}
        fileSizeLimit={maxFileSize} // MB
        onChange={(file) => {
          isFormChanged(true);
          setFileState(file);
          handleFormChange('invoice');
        }}
      />
      <Alert type="warning">
        Help prevent a privacy incident by ensuring that any supporting document
        uploaded here does not contain{' '}
        <a
          href="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act"
          target="_blank"
          rel="noreferrer"
        >
          personally identifiable information
        </a>{' '}
        (PII).
      </Alert>
    </div>
  );
};

ManagePmForm.propTypes = {
  isAOVehicle: PropTypes.bool.isRequired,
};

export default ManagePmForm;
