/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  flow,
  values,
  some,
  isEqual,
  keyBy,
  isNil,
  isNaN,
  isString,
  isFinite,
  isNull,
  isNumber,
} from 'lodash/fp';
import { RadioButton } from '@gsa/afp-component-library';
import { emdash } from 'components/common';
import { trim } from 'lodash';
import numeral from 'numeral';
import moment from 'moment-timezone';
import { STATES_LIST, KEY_CODE } from './consts';

// bool->{key:val}-> bool
export const hasValue = (bool = true) => flow(values, some(isEqual(bool)));

export const currencyFormat = '$0,0.00';
export const threeDecimalCurrencyFormat = '$0,0.000';
export const threeDecimalFormat = '0,0.000';

export const toNumValue = (num) => numeral(num).value();

export const changeNumberFormat = (value, numberFormat = '0,0') => {
  if (isNil(value)) {
    return '-';
  }
  return numeral(value).format(numberFormat);
};

export const strsToOptions = ({ strs = [], defaultVal = 'DEFAULT' }) => {
  if (defaultVal === 'NO-DEFAULT') {
    return [...strs.map((str) => ({ value: str, label: str }))];
  }
  return [
    { value: defaultVal, label: '-Select-' },
    ...strs.map((str) => ({ value: str, label: str })),
  ];
};

export const utcDateFormat = 'YYYY-MM-DDThh:mm:ssZ';
export const MMDDYYYDateFormat = 'MM/DD/YYYY';
export const shortDateFormat = 'YYYY-MM-DD';

export const isRightFormatDate = ({ dateVal, format = MMDDYYYDateFormat }) =>
  moment(dateVal, format, true).isValid();

export const ToUTCFormatDate = ({ dateVal, format = null }) =>
  moment.utc(dateVal).format(format);

export const USDateStrToUTCStr = (ds) =>
  ds
    ? `${ds.substr(6, 4)}-${ds.substr(0, 2)}-${ds.substr(3, 2)}T00:00:00Z`
    : '';

export const keyByState = keyBy('stateCode', STATES_LIST);

export const CurrencyCell = ({ value, format = currencyFormat }) => {
  return (
    <span className={value < 0 ? 'minusBalance' : ''}>
      {changeNumberFormat(value, format)}
    </span>
  );
};

export const reverseKeyAndValue = (data) => {
  if (data) {
    // eslint-disable-next-line array-callback-return, consistent-return
    return Object.keys(data).reduce((acc, key) => {
      if (acc) {
        acc[data[key]] = key;
        return acc;
      }
    }, {});
  }
  return data;
};

export const textToFloat = (text, decimal = 2) => {
  const parsed = toNumValue(text);
  if (isNaN(parsed) || isNil(parsed)) {
    return null;
  }
  return parseFloat(parsed.toFixed(decimal));
};

export const safeParseFloat = (num, defaultVal = null) => {
  if (isNil(num) || isNull(num)) {
    return defaultVal;
  }
  if (isString(num)) {
    const parsedNum = toNumValue(num);
    return isFinite(parsedNum) ? parsedNum : defaultVal;
  }

  if (isNumber(num)) {
    return isFinite(num) ? num : defaultVal;
  }
  return defaultVal;
};

export const getFieldsDataWithDisabled = ({
  fieldKey,
  editData = {},
  formFields = {},
  disableCriteria,
  disableExceptionKeys = [],
}) => {
  if (
    editData[fieldKey] ||
    formFields[fieldKey]?.default?.value ||
    formFields[fieldKey]?.default?.value === ''
  ) {
    const isDisable = !(
      !disableCriteria || disableExceptionKeys.includes(fieldKey)
    );

    return [
      [
        `${fieldKey}.default.value`,
        editData[fieldKey] || formFields[fieldKey]?.default?.value,
      ],
      [`${fieldKey}.default.disabled`, isDisable],
    ];
  }
  return undefined;
};

export const updateSort = ({ orderStr = '' }) => {
  const orderStrs = orderStr.split(' ');
  if (orderStrs.length !== 2) {
    throw new Error('wrong orderStr');
  }
  const onSortColumnName = trim(orderStrs[0], '`');
  const onSortColumnOrder = orderStrs[1];
  return [[onSortColumnName, onSortColumnOrder]];
};

export const onErrorFn = (setMessageFn) => (err) => {
  setMessageFn({
    type: 'error',
    message: `${err.message}`,
  });
};

export const orderQueryParser = (order) => {
  const fieldName = order[0][0];
  const orderCommend = order[0][1];
  if (fieldName && orderCommend) {
    return [[fieldName.split('.'), orderCommend].flatMap((x) => x)];
  }

  return null;
};

export const getOrDefaultVal = ({
  dataObject,
  dataKey,
  defaultVal = '',
  dataValConverter = (x) => x,
}) => {
  if (!dataObject) {
    return defaultVal;
  }

  return dataValConverter(dataObject[`${dataKey}`]) ?? defaultVal;
};

export const onlyAllowNumber = (e) => {
  if (['!', '@', '#', '$', '%', '^', '&', '*', '(', ')'].includes(e.key)) {
    return true;
  }
  if ([KEY_CODE.ENTER, KEY_CODE.BACK, KEY_CODE.TAB].includes(e.keyCode)) {
    return false;
  }
  if (e.keyCode >= 48 && e.keyCode < 58) {
    return false;
  }
  return true;
};
export const allowAlphaNumeric = (e) => {
  if (e.keyCode >= 65 && e.keyCode <= 90) {
    return false;
  }

  return onlyAllowNumber(e);
};

// we are  only get agency and Bureau's name and code now,
// default isAgencyCode is true which get agency's code or name, if you need the Bureau  name or code change isAgency  to false
// the test have all the example, in the future if we need to show the office we could change the isAgencyCode to codeType = 'agency'...
// the comment is for getCodeByOwnerShip , getNameByOwnerShip and getCodeAndNameByOwnerShip
export const getCodeByOwnerShip = ({
  ownershipTypeCode,
  code,
  customer,
  isAgencyCode = true,
}) => {
  let usingCode = code;
  if (ownershipTypeCode === 'GF') {
    usingCode = isAgencyCode
      ? customer?.customerAgencyCode
      : customer?.customerBureauCode;
  }
  return usingCode || '-';
};

export const getNameByOwnerShip = ({
  ownershipTypeCode,
  name,
  customer,
  isAgencyName = true,
}) => {
  let usingName = name;
  if (ownershipTypeCode === 'GF') {
    usingName = isAgencyName ? customer?.agency?.name : customer?.bureau?.name;
  }
  return usingName || '-';
};

export const getCodeAndNameByOwnerShip = ({
  ownershipTypeCode,
  name,
  code,
  customer,
  isAgency = true,
}) => {
  return `${getCodeByOwnerShip({
    ownershipTypeCode,
    code,
    customer,
    isAgencyCode: isAgency,
  })} - ${getNameByOwnerShip({
    ownershipTypeCode,
    name,
    customer,
    isAgencyName: isAgency,
  })}`;
};

export const getPoc = (contactData) => {
  const poc = {
    contactId: contactData?.contactId,
    email: contactData?.pocEmailAddress,
    firstName: contactData?.pocFirstName,
    lastName: contactData?.pocLastName,
    primaryPhone: contactData?.pocPhone1,
    primaryPhoneExt: contactData?.pocPhone1Ext,
    primaryAddress: contactData?.pocAddress1,
    secondaryAddress: contactData?.pocAddress2,
    tertiaryAddress: contactData?.pocAddress3,
    secondaryPhone: contactData?.pocPhone2,
    city: contactData?.pocCity,
    postalCode: contactData?.pocPostalCode,
    stateCode: contactData?.pocStateCode,
    countryCode: contactData?.pocPhone1CountryCode,
  };
  return poc;
};

export const vehToPDFpayload = (veh) => {
  const {
    id,
    agency,
    agencyCode,
    bureau,
    bureauCode,
    office,
    exemptPlate,
    subSectionCode,
    tag,
    vehicleMake,
    vehicleModel,
    modelCode,
    makeColor,
    modelYear,
    fuel,
    customer,
    assetContactAssociationPrimary,
    assetContactAssociationSecondary,
    ownershipTypeCode,
  } = veh;

  // eslint-disable-next-line prefer-destructuring
  let primaryPoC = veh.primaryPoC;
  // eslint-disable-next-line prefer-destructuring
  let secondaryPoC = veh.secondaryPoC;

  if (!primaryPoC) {
    primaryPoC = getPoc(assetContactAssociationPrimary?.pointOfContactPrimary);
  }
  if (!secondaryPoC) {
    secondaryPoC = getPoc(
      assetContactAssociationSecondary?.pointOfContactSecondary,
    );
  }
  return {
    id,
    agencyCode: getCodeByOwnerShip({
      ownershipTypeCode,
      code: agencyCode,
      customer,
    }),
    agency: {
      name: getNameByOwnerShip({
        ownershipTypeCode,
        name: agency?.name,
        customer,
      }),
    },
    bureauCode: getCodeByOwnerShip({
      ownershipTypeCode,
      code: bureauCode,
      customer,
      isAgencyCode: false,
    }),
    exemptPlate,
    bureau: {
      name: getNameByOwnerShip({
        ownershipTypeCode,
        name: bureau?.name,
        customer,
        isAgencyName: false,
      }),
    },
    subSectionCode,
    office,
    officeName: office ? { name: office.officeName } : null,
    tagNumber: tag?.id,
    tag,
    vehicleMake: { makeName: vehicleMake?.makeName },
    vehicleModel: {
      modelDescription: vehicleModel?.modelDescription || modelCode,
    },
    modelYear,
    makeColor,
    fuel: { description: fuel?.description },
    primaryPoC,
    secondaryPoC,
    customer,
    ownershipTypeCode,
  };
};

export const isPoBoxAddress = (address) => {
  // https://stackoverflow.com/questions/5680050/po-box-regular-expression-validation

  const poBoxRegExp =
    // eslint-disable-next-line no-useless-escape
    / *((#\d+)|((box|bin)[-. \/\\]?\d+)|(\bp[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|num|number)\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|num|number)? *\d+|(#|num|number) *\d+|\bpost\b *\d+)/i;

  return new RegExp(poBoxRegExp).test(address);
};

export const optionsToRadioButton = ({
  radioOptions,
  name,
  value,
  onChange,
  setValue,
}) =>
  radioOptions.map(({ radioValue, label, ...restProps }) => {
    return (
      <RadioButton
        name={name}
        key={radioValue}
        value={radioValue}
        checked={radioValue === value}
        label={<p>{label}</p>}
        {...restProps}
        onChange={(e) => {
          setValue(name, e.target.value);
          onChange(e.target.value);
        }}
      />
    );
  });

export const coercionBoolean = (value) => {
  if (value.toLowerCase() === 'yes') {
    return true;
  }
  return typeof value === 'string' ? !!+value : !!value;
};

export const coercionString = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value.toString();
};

export const VEHICLE_OWNERSHIP = {
  AO: 'Agency owned',
  CL: 'Commercial leased vehicle',
  GF: 'GSA leased vehicle',
};

export const formatDate = (date) => {
  let formattedDate;
  if (date === null) {
    formattedDate = emdash;
  } else {
    formattedDate = moment.utc(date).format('MM/DD/YYYY');
  }
  return formattedDate;
};

export const getCurrentOdometerText = (currentOdometer) =>
  `${changeNumberFormat(currentOdometer)} as of ${formatDate(new Date())}`;
